import * as sea from "node:sea";

export const fetchWP = async (path: string) => {
  const username = 'ligaportal';
  const password = 'Ligaportal2023';
  const basicAuth = btoa(`${username}:${password}`); // Encode credentials to Base64

  const headers = new Headers({
    'Authorization': `Basic ${basicAuth}`, // Set the Authorization header
    'Access-Control-Allow-Origin': '*',
  });

  return $fetch(path, {
      method: 'GET', // You can change the method to match your API requirements
      headers: headers,
    });
};

export const buildQuery = (data: object) => {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(data)) {
    searchParams.append(key, value)
  }

  return searchParams.toString();
}

